import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

import { cn } from "../../lib/utils";
import { buttonVariants } from "./button";

function Calendar({ className, classNames, showOutsideDays = true, ...props }) {
    return (
        <DayPicker
            captionLayout="dropdown-buttons"
            fromYear={1950}
            toYear={2025}
            showOutsideDays={showOutsideDays}
            className={cn("p-3", className)}
            classNames={{
                caption_dropdowns: "flex justify-between items-center w-64",
                vhidden: "hidden",
                months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                dropdown_icon: "hidden",
                dropdown:
                    "relative inline-flex items-center justify-center w-24 h-8 bg-white border border-neutral-200 rounded-md shadow-sm text-sm font-medium text-neutral-700 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent focus:ring-offset-neutral-50 focus:ring-opacity-50 focus:ring-offset-opacity-50 focus:ring-offset-accent ",

                rdp: "hidden bg-red-200",
                dropdown_year: "mx-2",
                dropdown_month: "mx-2",

                month: "space-y-4",
                caption:
                    "flex justify-center pt-1 relative items-center w-[360px]",
                caption_label: "text-lg font-medium",
                nav: "space-x-1 flex items-center",
                nav_button: cn(
                    buttonVariants({ variant: "outline" }),
                    "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
                ),
                nav_button_previous: "absolute left-1",
                nav_button_next: "absolute right-1",
                table: "w-full border-collapse space-y-1 ",
                // tr: 'justify-between',
                head_row: "flex justify-between",
                head_cell:
                    "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
                row: "flex w-full mt-2 justify-between",
                cell: "text-center text-lg p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
                day: cn(
                    buttonVariants({ variant: "ghost" }),
                    "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
                ),
                day_selected:
                    "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                day_today: "bg-accent text-accent-foreground",
                day_outside: "text-muted-foreground opacity-50",
                day_disabled: "text-muted-foreground opacity-50",
                day_range_middle:
                    "aria-selected:bg-accent aria-selected:text-accent-foreground",
                day_hidden: "invisible",
                ...classNames
            }}
            {...props}
        />
    );

    // return (
    //   <DayPicker
    //     showOutsideDays={showOutsideDays}
    //     dropdownMode="select"
    //     showMonthDropdown
    //     showYearDropdown
    // className={cn("p-3", className)}
    // classNames={{
    //   months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
    //   month: "space-y-4",
    //   caption: "flex justify-center pt-1 relative items-center",
    //   caption_label: "text-lg font-medium",
    //   nav: "space-x-1 flex items-center",
    //   nav_button: cn(
    //     buttonVariants({ variant: "outline" }),
    //     "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
    //   ),
    //   nav_button_previous: "absolute left-1",
    //   nav_button_next: "absolute right-1",
    //   table: "w-full border-collapse space-y-1",
    //   head_row: "flex",
    //   head_cell:
    //     "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
    //   row: "flex w-full mt-2",
    //   cell: "text-center text-lg p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
    //   day: cn(
    //     buttonVariants({ variant: "ghost" }),
    //     "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
    //   ),
    //   day_selected:
    //     "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
    //   day_today: "bg-accent text-accent-foreground",
    //   day_outside: "text-muted-foreground opacity-50",
    //   day_disabled: "text-muted-foreground opacity-50",
    //   day_range_middle:
    //     "aria-selected:bg-accent aria-selected:text-accent-foreground",
    //   day_hidden: "invisible",
    //   ...classNames,
    // }}
    //     components={{
    //       IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
    //       IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
    //     }}
    //     {...props}
    //   />
    // );
}
Calendar.displayName = "Calendar";

export { Calendar };
